import { useState } from 'react';
import ReactSelect from 'components/Select';
import DatePicker from 'components/DatePicker';
// import { Loading } from 'components';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { SelectOption } from 'types/types';
import { CustomerPackage } from '__generated__/graphql';
import { hasValidPackage, packageOverlapping, setStartOfDay } from './service';

interface PackageCreateProps {
  customerPackages: CustomerPackage[];
  onClose: () => void;
  onCreate: (data: CustomerPackage) => void;
}

export default function PackageCreate({ customerPackages, onClose, onCreate }: PackageCreateProps) {
  const [validation, setValidation] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [supportedPerson, setSupportedPerson] = useState<SelectOption>();
  const { customers } = useGetCustomers({});

  const onSavePackage = () => {
    setValidation(null);
    if (startDate && supportedPerson) {
      const newStartDate = setStartOfDay(startDate);
      const packageAlreadyValid = hasValidPackage(customerPackages, supportedPerson.value);

      if (packageAlreadyValid) {
        setValidation('This supported person already has a package');
        return;
      }

      const hasOverlap = packageOverlapping(customerPackages, supportedPerson.value, newStartDate);

      if (hasOverlap) {
        setValidation('This date overlaps with an existing package');
        return;
      }

      onCreate({
        startDate: newStartDate,
        endDate: null,
        customerId: supportedPerson.value,
        externalId: supportedPerson.id || '',
        name: supportedPerson.label,
      });
    }
  };

  // if (loading) return <Loading />;

  const supportedPersonOptions = customers.map((s) => {
    return { label: `${s.firstName} ${s.lastName}`, value: s.id, id: s.externalId };
  });

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Create Package</div>
            {validation && <div className="text-sm leading-lg mt-1 mb-4 text-red-800">{validation}</div>}
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Supported Person</div>
              <div className="mt-2">
                <ReactSelect
                  testId="package-customerId"
                  options={supportedPersonOptions}
                  onChange={(e) => setSupportedPerson(e)}
                  selectedValue={supportedPerson}
                  searchable
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Start Date</div>
              <div className="mt-2">
                <DatePicker testId="package-start-date" value={startDate ?? undefined} onChange={setStartDate} />
              </div>
            </div>
            <div className="flex justify-between items-center mt-5">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                data-cy="save-package-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSavePackage}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
