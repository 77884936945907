import { useQuery, useMutation, gql } from '@apollo/client';
import {
  AddMileageReportingHourInput,
  DeleteMileageReportingHourInput,
  ReportingHours,
  ReportingHoursByDateQuery,
  ReportingHoursPeriod,
  ReportingHoursPeriodQuery,
  ReportingHoursSearch,
  SaveReportingHoursInput,
  SubmitReportingHourInput,
  SyncReportingHourInput,
  UpdateReportingHourInput,
} from '../../__generated__/graphql';

export const GET_REPORTING_HOURS_PERIOD = gql`
  query GetReportingHoursPeriod($query: ReportingHoursPeriodQuery) {
    GetReportingHoursPeriod(query: $query) {
      id
      teamId
      teamName
      supportWorkerId
      startDateTime
      endDateTime
      fullName
      position
      employmentStatus
      contractedHours
      birdieId
      annualLeave
      sickLeave
      meetings
      status
      mileage
      mileageWithCustomer
      mileageOther
      timeOnVisits
      bankedHours
      timeOnActivities
      bankedHoursAssignedOnVisits
      remainingBankedHours
      overtime
    }
  }
`;

export const GET_ALL_REPORTING_HOURS_PERIOD = gql`
  query GetAllReportingHoursPeriod($query: ReportingHoursByDateQuery) {
    GetAllReportingHoursPeriod(query: $query) {
      id
      teamId
      teamName
      supportWorkerId
      startDateTime
      endDateTime
      fullName
      position
      employmentStatus
      contractedHours
      birdieId
      annualLeave
      sickLeave
      meetings
      status
      mileage
      mileageWithCustomer
      mileageOther
      timeOnVisits
      bankedHours
      timeOnActivities
      bankedHoursAssignedOnVisits
      remainingBankedHours
      overtime
    }
  }
`;

export const SAVE_REPORTING_HOURS = gql`
  mutation SaveReportingHours($input: SaveReportingHoursInput) {
    SaveReportingHours(input: $input) {
      id
      date
      message
    }
  }
`;

export const GET_REPORTING_HOURS = gql`
  query GetReportingHours($query: ReportingHoursSearch) {
    GetReportingHours(query: $query) {
      id
      reportingHoursPeriodId
      teamId
      shiftId
      secondarySupportWorkerId
      type
      bankedHours
      annualLeave
      shift {
        id
        startDateTime
        endDateTime
        type
        rotaId
        ownerId
      }
      rota {
        id
        teamId
        startRotaDateTime
        endRotaDateTime
        status
      }
      visit {
        id
        shiftId
        visitId
        startDateTime
        endDateTime
        travelTime
        teamId
        week
        status
        type
        mileage
        travelWithCustomerMileage
        travelWithCustomerDestination
        notes
        whoCancelled
        cancelledDateTime
        oneOffVisitReason
        oneOffVisitNote
        isToBeRescheduled
        activityType
        rotaId
        birdieId
        customerId
        supportWorkerId
        secondarySupportWorkerId
        origin {
          rotaId
          teamId
          visitId
          supportWorkerId
          fullName
        }
        destination {
          rotaId
          teamId
          visitId
          supportWorkerId
          fullName
        }
      }
      owner {
        fullName
        teamId
        teamName
        position
        employmentStatus
        contractedHours
        birdieId
      }
      mileage {
        date
        mileage
        mileageDestination
        notes
      }
    }
  }
`;

export const UPDATE_REPORTING_HOUR = gql`
  mutation UpdateReportingHour($input: UpdateReportingHourInput) {
    UpdateReportingHour(input: $input) {
      id
      date
      message
    }
  }
`;

export const ADD_MILEAGE_REPORTING_HOUR = gql`
  mutation AddMileageReportingHour($input: AddMileageReportingHourInput) {
    AddMileageReportingHour(input: $input) {
      id
      date
      message
    }
  }
`;

export const DELETE_MILEAGE_REPORTING_HOUR = gql`
  mutation DeleteMileageReportingHour($input: DeleteMileageReportingHourInput) {
    DeleteMileageReportingHour(input: $input) {
      id
      date
      message
    }
  }
`;

export const SYNC_REPORTING_HOUR = gql`
  mutation SyncReportingHour($input: SyncReportingHourInput) {
    SyncReportingHour(input: $input) {
      date
      message
    }
  }
`;

export const SUBMIT_REPORTING_HOUR = gql`
  mutation SubmitReportingHour($input: SubmitReportingHourInput) {
    SubmitReportingHour(input: $input) {
      date
      message
    }
  }
`;

interface GeAlltReportingHoursPeriodResponse {
  GetAllReportingHoursPeriod: ReportingHoursPeriod[];
}

interface GetReportingHoursPeriodResponse {
  GetReportingHoursPeriod: ReportingHoursPeriod[];
}

interface GetReportingHoursResponse {
  GetReportingHours: ReportingHours[];
}

const refetch = (input: Record<string, unknown>) => ({
  refetchQueries: [
    {
      query: GET_REPORTING_HOURS,
      variables: {
        query: { teamId: input.teamId, supportWorkerId: input.supportWorkerId, id: input.id },
      },
    },
    {
      query: GET_REPORTING_HOURS_PERIOD,
      variables: {
        query: { teamId: input.teamId, supportWorkerId: input.supportWorkerId },
      },
    },
  ],
});

export const useGetAllReportingHoursPeriod = (query: ReportingHoursByDateQuery) => {
  const { data, loading, error } = useQuery<GeAlltReportingHoursPeriodResponse>(GET_ALL_REPORTING_HOURS_PERIOD, {
    variables: {
      query: { ...query },
    },
  });

  return { reportingHoursPeriod: data?.GetAllReportingHoursPeriod || [], loading, error };
};

export const useGetReportingHoursPeriod = (query: ReportingHoursPeriodQuery) => {
  const { data, loading, error } = useQuery<GetReportingHoursPeriodResponse>(GET_REPORTING_HOURS_PERIOD, {
    skip: !query.supportWorkerId,
    variables: {
      query: { ...query },
    },
  });

  return { reportingHoursPeriod: data?.GetReportingHoursPeriod || [], loading, error };
};

export const useGetReportingHours = (query: ReportingHoursSearch) => {
  const { data, loading, error } = useQuery<GetReportingHoursResponse>(GET_REPORTING_HOURS, {
    variables: {
      query: { ...query },
    },
  });

  return { reportingHours: data?.GetReportingHours || [], loading, error };
};

export const useSaveReportingHours = (input: SaveReportingHoursInput) => {
  const [saveReportingHours, mutationSaveReportingHours] = useMutation(SAVE_REPORTING_HOURS, {
    refetchQueries: [
      {
        query: GET_REPORTING_HOURS,
        variables: {
          query: { teamId: input.teamId, supportWorkerId: input.supportWorkerId },
        },
      },
      {
        query: GET_REPORTING_HOURS_PERIOD,
        variables: {
          query: { ...input },
        },
      },
    ],
  });

  return { saveReportingHours, mutationSaveReportingHours };
};

export const useUpdateReportingHour = (input: UpdateReportingHourInput) => {
  const [updateReportingHour, mutationUpdateReportingHour] = useMutation(UPDATE_REPORTING_HOUR, refetch(input));
  return { updateReportingHour, mutationUpdateReportingHour };
};

export const useAddMileageReportingHour = (input: AddMileageReportingHourInput) => {
  const [addMileageReportingHour, mutationAddMileageReportingHour] = useMutation(ADD_MILEAGE_REPORTING_HOUR, refetch(input));
  return { addMileageReportingHour, mutationAddMileageReportingHour };
};

export const useDeleteMileageReportingHour = (input: DeleteMileageReportingHourInput) => {
  const [deleteMileageReportingHour, mutationDeleteMileageReportingHour] = useMutation(DELETE_MILEAGE_REPORTING_HOUR, refetch(input));
  return { deleteMileageReportingHour, mutationDeleteMileageReportingHour };
};

export const useSyncReportingHour = (input: SyncReportingHourInput, id: string | undefined | null) => {
  const [syncReportingHour, mutationSyncReportingHour] = useMutation(SYNC_REPORTING_HOUR, refetch({ ...input, id }));
  return { syncReportingHour, mutationSyncReportingHour };
};

export const useSubmitReportingHour = (input: SubmitReportingHourInput) => {
  const [submitReportingHour, mutationSubmitReportingHour] = useMutation(SUBMIT_REPORTING_HOUR, refetch(input));
  return { submitReportingHour, mutationSubmitReportingHour };
};
