import { useQuery, useLazyQuery, gql, useMutation } from '@apollo/client';
import { GetVisitsForCustomerByDateRangeSearch, Invoice, InvoiceSearch, Shift } from '__generated__/graphql';

export const GET_CUSTOMER_INVOICES = gql`
  query GetCustomerInvoices($query: InvoiceSearch) {
    GetCustomerInvoices(query: $query) {
      id
      customerId
      teamId
      name
      dueDate
      invoiceDate
      invoiceNumber
      payrollStartDate
      payrollEndDate
      dateUpdated
      invoiceType
      address
      care {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      proRata {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      oneOffVisits {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      cancelledVisits {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      mileage {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      thrive {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      thriveVat {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
    }
  }
`;

export const GET_CUSTOMER_INVOICE = gql`
  query GetCustomerInvoice($query: InvoiceSearch) {
    GetCustomerInvoice(query: $query) {
      id
      customerId
      teamId
      name
      dueDate
      invoiceDate
      invoiceNumber
      payrollStartDate
      payrollEndDate
      dateUpdated
      invoiceType
      address
      packagePrices {
        id
        startDate
        endDate
        fundingSource
        type
        monthlyValue
        hourlyValue
        numberOfHours
        note
      }
      thriveBookingItems {
        id
        included
        customerName
        endDateTime
        partnerName
        price
        product
        startDateTime
      }
      careItems {
        id
        type
        status
        included
        startDateTime
        endDateTime
        cancelledDateTime
        whoCancelled
        travelWithCustomerMileage
        travelWithCustomerDestination
      }
      mileageItems {
        id
        type
        status
        included
        startDateTime
        endDateTime
        cancelledDateTime
        whoCancelled
        travelWithCustomerMileage
        travelWithCustomerDestination
      }
      care {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      proRata {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      oneOffVisits {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      cancelledVisits {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      mileage {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      thrive {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
      thriveVat {
        ContactName
        EmailAddress
        POAddressLine1
        POAddressLine2
        POAddressLine3
        POAddressLine4
        POCity
        PORegion
        POPostalCode
        POCountry
        InvoiceNumber
        Reference
        InvoiceDate
        DueDate
        Total
        Description
        Quantity
        UnitAmount
        Discount
        TaxAmount
      }
    }
  }
`;

export const SAVE_INVOICE = gql`
  mutation SaveInvoice($input: SaveInvoiceInput) {
    SaveInvoice(input: $input) {
      id
      date
      message
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($input: SaveInvoiceInput) {
    CreateInvoice(input: $input) {
      id
      date
      message
    }
  }
`;

export const GET_VISITS_FOR_CUSTOMER = gql`
  query GetVisitsForCustomerByDateRange($query: GetVisitsForCustomerByDateRangeSearch) {
    GetVisitsForCustomerByDateRange(query: $query) {
      id
      shiftId
      visitId
      startDateTime
      endDateTime
      travelTime
      teamId
      week
      status
      type
      mileage
      travelWithCustomerMileage
      travelWithCustomerDestination
      notes
      whoCancelled
      cancelledDateTime
      oneOffVisitReason
      oneOffVisitNote
      isToBeRescheduled
      activityType
      rotaId
      birdieId
      customerId
      supportWorkerId
      secondarySupportWorkerId
      origin {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
      }
      destination {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
      }
    }
  }
`;

export const GET_MILEAGE_VISITS_FOR_CUSTOMER = gql`
  query GetMileageVisitsForCustomerByDateRange($query: GetVisitsForCustomerByDateRangeSearch) {
    GetMileageVisitsForCustomerByDateRange(query: $query) {
      id
      shiftId
      visitId
      startDateTime
      endDateTime
      travelTime
      teamId
      week
      status
      type
      mileage
      travelWithCustomerMileage
      travelWithCustomerDestination
      notes
      whoCancelled
      cancelledDateTime
      oneOffVisitReason
      oneOffVisitNote
      isToBeRescheduled
      activityType
      rotaId
      birdieId
      customerId
      supportWorkerId
      secondarySupportWorkerId
      origin {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
      }
      destination {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
      }
    }
  }
`;

export const CREATE_INVOICE_FOR_ALL = gql`
  mutation CreateInvoiceForAll($input: SaveInvoiceInput) {
    CreateInvoiceForAll(input: $input) {
      id
      date
      message
    }
  }
`;

interface GetInvoicesResponse {
  GetCustomerInvoices: Invoice[];
}

interface GetInvoiceResponse {
  GetCustomerInvoice: Invoice;
}

interface GetVisitsResponse {
  GetVisitsForCustomerByDateRange: Shift[];
}

interface GetMileageVisitsResponse {
  GetMileageVisitsForCustomerByDateRange: Shift[];
}

export const useGetCustomerInvoice = (query: InvoiceSearch) => {
  const { data, loading, error } = useQuery<GetInvoiceResponse>(GET_CUSTOMER_INVOICE, {
    variables: {
      query,
    },
  });

  return { invoice: data?.GetCustomerInvoice, loading, error };
};

export const useGetCustomerInvoices = () => {
  const [GetCustomerInvoices, { data, loading, error }] = useLazyQuery<GetInvoicesResponse>(GET_CUSTOMER_INVOICES);
  return { GetCustomerInvoices, loading, error, invoices: data?.GetCustomerInvoices || [] };
};

export const useSaveInvoice = (query: InvoiceSearch) => {
  const [saveInvoice, mutationSaveInvoice] = useMutation(SAVE_INVOICE, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_INVOICE,
        variables: {
          query,
        },
      },
    ],
  });

  return { saveInvoice, mutationSaveInvoice };
};

export const useCreateInvoice = () => {
  const [saveInvoice, mutationSaveInvoice] = useMutation(CREATE_INVOICE);
  return { saveInvoice, mutationSaveInvoice };
};

export const useCreateInvoiceForAll = (date: number) => {
  const [saveInvoice, mutationSaveInvoice] = useMutation(CREATE_INVOICE_FOR_ALL, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_INVOICES,
        variables: {
          query: {
            date,
          },
        },
      },
    ],
  });
  return { saveInvoice, mutationSaveInvoice };
};

export const useGetVisitsForCustomerByDateRange = (query: GetVisitsForCustomerByDateRangeSearch) => {
  const { data, loading, error } = useQuery<GetVisitsResponse>(GET_VISITS_FOR_CUSTOMER, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { visits: data?.GetVisitsForCustomerByDateRange || [], loading, error };
};

export const useGetMileageVisitsForCustomerByDateRange = (query: GetVisitsForCustomerByDateRangeSearch) => {
  const { data, loading, error } = useQuery<GetMileageVisitsResponse>(GET_MILEAGE_VISITS_FOR_CUSTOMER, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { visits: data?.GetMileageVisitsForCustomerByDateRange || [], loading, error };
};
