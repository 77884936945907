import { InputField } from 'components/Fields/InputField';
import { DateField } from 'components/Fields/DateField';

export default function InvoiceUpdate() {
  return (
    <div className="mt-8 sm:mt-12">
      <DateField label="Invoice Date" name="invoiceDate" />
      <DateField label="Due Date" name="dueDate" />
      <InputField label="Invoice Number" placeholder="InvoiceNumber" name="invoiceNumber" />
      <InputField label="Name" placeholder="Name" name="name" />
      <div className="flex justify-end">
        <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      </div>
    </div>
  );
}
