import React, { useState, useEffect } from 'react';
import getTime from 'date-fns/getTime';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Alert from '@mui/material/Alert';
import { activityTypes } from 'types/types';
import { Shift, AddActivityToShiftInput } from '__generated__/graphql';
import { clockMessage } from 'config/messages';
import { Loading } from 'components';
import { ShiftType, VisitStatus } from 'pages/ReportingHours/service';
import { getActiveVisitsForShift, shiftExceedsMaximumLength, ShiftLengthMessageError } from '../service';

interface AddActivityToShiftProps {
  shifts: Shift[];
  toggleAddActivityToShiftModal: (value: boolean) => void;
  addActivityToShiftModalState: boolean;
  selectedActivity: Shift | null;
  onSaveActivity: (activity: AddActivityToShiftInput) => void;
  apiLoading: boolean;
}

export default function AddActivityToShift({
  shifts,
  toggleAddActivityToShiftModal,
  addActivityToShiftModalState,
  selectedActivity,
  onSaveActivity,
  apiLoading,
}: AddActivityToShiftProps) {
  const [startDateTime, setStartDateTime] = useState<number>(0);
  const [endDateTime, setEndDateTime] = useState<number>(0);
  const [shiftRun, setShiftRun] = useState<string | null>(null);
  const [activityType, setActivityType] = useState<string | null>(null);
  const [notes, setNotes] = useState('');
  const [validation, setValidation] = useState<string>('');

  const shiftsForRun = shifts.filter((s: Shift) => s.shiftId === selectedActivity?.shiftId);
  const activeShifts = getActiveVisitsForShift(shiftsForRun);

  useEffect(() => {
    if (selectedActivity) {
      setShiftRun(selectedActivity.shiftId);
      setStartDateTime(selectedActivity.startDateTime);
      setEndDateTime(selectedActivity.endDateTime);
      setActivityType(activityTypes[0].value);
    }
  }, [selectedActivity]);

  const onChangeStartDateTime = (dateTime: number | null) => {
    if (dateTime) {
      setStartDateTime(dateTime);
      setValidation('');
    }
  };

  const onChangeEndDateTime = (dateTime: number | null) => {
    if (dateTime) {
      setEndDateTime(getTime(dateTime));
      setValidation('');
    }
  };

  const onChangeActivity = (a: string) => {
    setActivityType(a);
    setValidation('');
  };

  const onChangeNotes = (note: string) => {
    setNotes(note);
    setValidation('');
  };

  const saveActivity = () => {
    setValidation('');
    if (!selectedActivity) return;

    if (getTime(startDateTime ?? 0) >= getTime(endDateTime ?? 0) || getTime(startDateTime ?? 0) === getTime(endDateTime ?? 0)) {
      setValidation('Please provide a valid shift date time; end time should be greater than start time');
      return;
    }

    if (getTime(startDateTime) < getTime(selectedActivity?.shiftRun?.startDateTime || 0)) {
      setValidation('Please provide a activity start time that is not less than the shift start time');
      return;
    }

    if (getTime(endDateTime) > getTime(selectedActivity?.shiftRun?.endDateTime || 0)) {
      setValidation('Please provide a activity end time that is not greater than the shift end time');
      return;
    }

    if (!notes || notes.length === 0) {
      setValidation('Please provide some notes for this activity');
      return;
    }

    const newActivity = {
      startDateTime: getTime(startDateTime),
      endDateTime: getTime(endDateTime),
      shiftId: shiftRun,
      activityType,
      notes,
    };

    const newShift = [
      ...activeShifts,
      { ...newActivity, id: '', visitId: '', shiftId: selectedActivity.shiftId, status: VisitStatus.active, teamId: selectedActivity.teamId },
    ].sort((x, y) => (x?.startDateTime || 0) - (y?.startDateTime || 0));

    if (shiftExceedsMaximumLength(newShift)) {
      setValidation(ShiftLengthMessageError(ShiftType.activity));
      return;
    }

    if (shiftRun && activityType) {
      onSaveActivity(newActivity);
    }
  };

  return (
    <Dialog open={addActivityToShiftModalState} onClose={() => toggleAddActivityToShiftModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Add activity to shift</DialogTitle>
      <DialogContent>
        {validation && (
          <Alert variant="filled" severity="error" sx={{ width: '90%', marginLeft: '1em', marginBottom: '1em' }}>
            {validation}
          </Alert>
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '98%' }}>
            <Typography>Start Time</Typography>
            <TimePicker
              ampm={false}
              minutesStep={5}
              value={startDateTime}
              onChange={onChangeStartDateTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
          <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '98%' }}>
            <Typography>End Time</Typography>
            <TimePicker
              ampm={false}
              minutesStep={5}
              value={endDateTime}
              onChange={onChangeEndDateTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
          <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '98%' }}>
            <Typography>Activity</Typography>
            <Select value={activityType} onChange={(e) => onChangeActivity(e.target.value as string)}>
              {activityTypes.map((option) => (
                <MenuItem key={option.value} value={option.value.toString()}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '98%' }}>
            <Typography>Notes</Typography>
            <textarea
              style={{ width: '100%', border: '1px solid #ddd', padding: '1em' }}
              rows={5}
              value={notes}
              onChange={(e) => onChangeNotes(e.target.value)}
            />
          </FormControl>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions sx={{ padding: { xs: '2em', sm: '1em' } }}>
        <Button variant="contained" onClick={() => toggleAddActivityToShiftModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button variant="contained" onClick={() => saveActivity()}>
            Add
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
